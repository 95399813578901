exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alumni-js": () => import("./../../../src/pages/alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-apply-js": () => import("./../../../src/pages/apply.js" /* webpackChunkName: "component---src-pages-apply-js" */),
  "component---src-pages-campaign-js": () => import("./../../../src/pages/campaign.js" /* webpackChunkName: "component---src-pages-campaign-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-consultancy-js": () => import("./../../../src/pages/consultancy.js" /* webpackChunkName: "component---src-pages-consultancy-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-operatingmodel-js": () => import("./../../../src/pages/operatingmodel.js" /* webpackChunkName: "component---src-pages-operatingmodel-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-programs-amp-js": () => import("./../../../src/pages/programs/amp.js" /* webpackChunkName: "component---src-pages-programs-amp-js" */),
  "component---src-pages-programs-coreacumen-js": () => import("./../../../src/pages/programs/coreacumen.js" /* webpackChunkName: "component---src-pages-programs-coreacumen-js" */),
  "component---src-pages-programs-dlp-js": () => import("./../../../src/pages/programs/dlp.js" /* webpackChunkName: "component---src-pages-programs-dlp-js" */),
  "component---src-pages-programs-enterprenuership-js": () => import("./../../../src/pages/programs/enterprenuership.js" /* webpackChunkName: "component---src-pages-programs-enterprenuership-js" */),
  "component---src-pages-programs-labourmarket-js": () => import("./../../../src/pages/programs/labourmarket.js" /* webpackChunkName: "component---src-pages-programs-labourmarket-js" */),
  "component---src-pages-programs-rhrn-js": () => import("./../../../src/pages/programs/rhrn.js" /* webpackChunkName: "component---src-pages-programs-rhrn-js" */),
  "component---src-pages-programs-sbiz-js": () => import("./../../../src/pages/programs/sbiz.js" /* webpackChunkName: "component---src-pages-programs-sbiz-js" */),
  "component---src-pages-programs-vit-js": () => import("./../../../src/pages/programs/vit.js" /* webpackChunkName: "component---src-pages-programs-vit-js" */),
  "component---src-pages-programs-wearebits-js": () => import("./../../../src/pages/programs/wearebits.js" /* webpackChunkName: "component---src-pages-programs-wearebits-js" */),
  "component---src-pages-programs-yact-js": () => import("./../../../src/pages/programs/yact.js" /* webpackChunkName: "component---src-pages-programs-yact-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-students-js": () => import("./../../../src/pages/students.js" /* webpackChunkName: "component---src-pages-students-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-volunteer-js": () => import("./../../../src/pages/volunteer.js" /* webpackChunkName: "component---src-pages-volunteer-js" */),
  "component---src-pages-whatwedo-js": () => import("./../../../src/pages/whatwedo.js" /* webpackChunkName: "component---src-pages-whatwedo-js" */),
  "component---src-pages-whoweare-js": () => import("./../../../src/pages/whoweare.js" /* webpackChunkName: "component---src-pages-whoweare-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

